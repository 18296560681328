import * as React from 'react';
import { HtmlHeadInclude, IRequestContext } from '@msdyn365-commerce/core';
import MsDyn365 from '@msdyn365-commerce/core';

const isDev = false; // process.env.NODE_ENV === 'development';

type SharedScriptsProps = {
    googleTagManagerId?: string;
    bazaarVoiceEnv?: string;
    request: IRequestContext;
    sailthruCustomerId?: string;
};

const SharedScripts = ({ googleTagManagerId, bazaarVoiceEnv, sailthruCustomerId, request }: SharedScriptsProps) => {
    const { locale } = request;

    const userEmail = request.user.emailAddress;

    const bvLocale = (locale || 'en-CA').replace('-', '_');
    const bvScript = `https://apps.bazaarvoice.com/deployments/henrys/main_site/${bazaarVoiceEnv || 'staging'}/${bvLocale}/bv.js`;

    const bvHandlerScript = `
window.bvCallback = function (BV) {
    BV.reviews && BV.reviews.on('show', function () {
        var elem = document.getElementsByClassName("hei-bv-reviews")[0];
        elem && elem.click();
    });
}
    `;

    React.useEffect(() => {
        if (googleTagManagerId && !isDev) {
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s) as HTMLScriptElement,
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode?.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', googleTagManagerId);
        }
    }, [googleTagManagerId]);

    // if (isDev) {
    //     React.useEffect(() => {
    //         const bodyTag = document.getElementsByTagName('body')[0];

    //         const script = document.createElement('script');
    //         script.async = true;
    //         script.src = `/assets/gtm.js`;
    //         bodyTag.appendChild(script);
    //     }, []);
    // }

    // if (process.env.NODE_ENV === 'development') {
    //     userEmail = 'elin@bicameral.ca';
    // }

    var isBrowser = MsDyn365.isBrowser;
    React.useEffect(() => {
        if (sailthruCustomerId && isBrowser && !window.sailthruInit && window.Sailthru) {
            window.sailthruInit = true;
            window.Sailthru.init({ customerId: sailthruCustomerId });
        }
    }, [isBrowser, sailthruCustomerId]);

    React.useEffect(() => {
        if (userEmail && isBrowser && window.sailthruInit && window.Sailthru && !request.cookies.get('sailthru_hid').value) {
            // Set SailThru hid
            window.Sailthru.integration('userSignUp', {
                email: userEmail,
                vars: {}
            });
        }
    }, [isBrowser, userEmail]);

    return (
        <>
            <HtmlHeadInclude>
                {/* tslint:disable-next-line: react-no-dangerous-html */}
                <script data-load-point='headStart' dangerouslySetInnerHTML={{ __html: bvHandlerScript }} />
                <script data-load-point='headStart' src={bvScript} />

                <script data-load-point='headEnd' src='https://ak.sail-horizon.com/spm/spm.v1.min.js' async></script>
            </HtmlHeadInclude>
        </>
    );
};

export default SharedScripts;
